import React from "react"
// import {Link} from 'gatsby'
import Layout from '../component/layout'
import Image from '../component/image'
import Link from '../component/link'
import Title from '../component/typography/title'
import SubTitle from '../component/typography/subtitle'
import PageTitle from "../component/typography/pageTitle"
import JourneyOmnidya from "../component/journeyOmnidya"

const AboutPage = () => {
  return(
    <Layout page="aboutus-header-bg" title="About Us" captions="Join the fastest growing auto insurance company in the West (and soon, the U.S.)">
      <div className="container-xxl">
        <div className="d-flex justify-content-center py-5">
          <Link to="#" className="btn btn-blue btn-pill text-wrap">
            Omnidya
          </Link>
          <Link to="#ourMission" className="btn btn-rounded btn-pill text-wrap">
            Our Mission
          </Link>
          <Link to="#ourTeam" className="btn btn-rounded btn-pill text-wrap">
            Our Team
          </Link>
        </div>
        <div className="header-slogan text-center pb-5">
          Hey. We're Omnidya.
        </div>
        <div className="row justify-content-center mb-5">
          <div className="col-lg-8 col">
            <Title className="text-center" title="How did it get to this?" />
            <p>
              Insurance hasn’t changed much over the years, and it definitely hasn’t kept up with modern technology. Omnidya was founded from a simple thought: What if we combined AI, data transparency, and a great customer experience, to make insurance fun and fully transparent?
            </p>
          </div>
        </div>
        <div className="row justify-content-center">
          <div className="col-auto col-lg-8">
            <div className="row justify-content-center justify-content-md-between text-center text-sm-left pb-5">
              <div className="col-sm-5 col-md-5 col-lg-4 mb-3">
                <Image src="aboutus-img2.png" className="img-fluid rounded-xlg" />
              </div>
              <div className="col-sm col-md col-lg">
                <SubTitle title="We're Omnidya." className="mb-3" />
                <div className="h-l-2">
                  <p>
                    Omnidya ['omni'dra]
                  </p>
                  <p>
                    Omni ['omni']: coming from the latin root omnis, meaning all encompassing
                  </p>
                  <p>
                    Vidya [v'idra]: derived from Sanskrit meaning "correct knowledge"
                  </p>
                  <p>
                    Omnidya keeps money in your pocket and encourages you to drive safer.
                  </p>
                  {/* <p>And decided to shop around for thebest insurance. The standards? He wanted something that was rated high in customer satisfaction and wouldn't break the bank.</p>
                  <p>But it took him the whold weekend.</p>
                  <p>So he founded Omnidya with the intent of making everything easier and faster. Then Akshay and Dave joined.</p> */}
                </div>
              </div>
            </div>
            <div className="row justify-content-center justify-content-md-between text-center text-sm-left pb-5">
              <div className="col-sm-5 col-md-5 col-lg-4 order-sm-last mb-3">
                <Image src="aboutus-img.png" />
              </div>
              <div className="col-sm col-md col-lg order-sm-first">
                <SubTitle title="The idea grew..." className="mb-3" />
                <div class="h-l-2">
                  <p>Our offering evolved from a next-level negotiation platform to hyper-personalized insurance with complimentary IoT devices.</p>
                  <p>Driven by the desire for positive change, we're starting with auto insurance. Up next? Home insurance. After that?</p>
                  <p>Everything else.</p>
                </div>
              </div>
            </div>
            <div className="py-5" id="ourMission">
              <PageTitle title="Our Mission" className="text-center" />
              <div className="py-5">
                <Image src="ourMIssionBanner.png" className="img-fluid" />
              </div>
              <div class="row">
                <div class="col-xs col-sm-6 col-lg-5">
                  <p class="h-l-2">
                    Omnidya is advancing the insurance industry by leveraging emerging technologies to create a comprehensive, data-driven solution that is not only easy to use, but fun!
                  </p>
                </div>
                <div class="col-xs col-sm-6 col-lg-7">
                  <div class="h-l-3">
                    “And since life is too short, 
                    we’ll make everything fun while we’re at it.” 
                  </div>
                </div>
              </div>
            </div>
            <div className="py-5 text-center" id="ourTeam">
              <PageTitle title="Our Team" />
              <p class="h-l-1 pb-5 pt-3 grey2">The faces behind your new favorite auto insurance company. It's good to be a nerd. </p>
              <div className="row">
                <div className="col-xs col-md-4">
                  <Image src="teammember1.png" fixed />
                  <div className="userName py-2">Kumar Patel</div>
                  <p class="userPosition">
                    Founder/CEO
                  </p>
                  <div className="d-flex justify-content-center pb-3">
                    <Link target="_blank" to="https://www.linkedin.com/in/kumarpatel888"><Image src="linkedin.svg" fixed className="mx-1" /></Link>
                    {/* <Link to="#"><Image src="twitter.svg" fixed className="mx-1" /></Link> */}
                  </div>
                </div>
                <div className="col-xs col-md-4">
                  <Image src="teamMember2.png" fixed />
                  <div className="userName py-2">Akshay Vachhani</div>
                  <p class="userPosition">
                    Co-Founder/CTO
                  </p>
                  <div className="d-flex justify-content-center pb-3">
                    <Link target="_blank" to="https://www.linkedin.com/in/akshayvachhani"><Image src="linkedin.svg" className="mx-1" /></Link>
                  </div>
                </div>
                <div className="col-xs col-md-4">
                  <Image src="teamMember3.png" fixed />
                  <div className="userName py-2">Dave Thrash</div>
                  <p class="userPosition">
                    Co-Founder
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <JourneyOmnidya gutter />  
    </Layout>
  )
}

export default AboutPage