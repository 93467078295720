import React from 'react'

const PageTitle = (props) => {
  const { title, className } = props
  return(
    <div className={`page-title ${className}`}>
      {title}
    </div>
  )
}

export default PageTitle